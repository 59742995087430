import Logo from "../images/logo.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";

function LandingView() {
  const navigate = useNavigate();
  const handleStartClick = () => {
    navigate("/onboard"); 
  }

  return (
    <div className="min-h-screen flex flex-col p-12 justify-content items-center bg-base-200">
      <Header/>
      <div className="shadow-xl w-96 flex flex-col bg-base-100 card p-12">
        <img src={Logo} className="w-36"/>
        <h1 className="font-bold text-2xl mt-4">LettuceTalk</h1>
        <h2 className="text-lg mt-2">A card game to spark caregiving conversations for Korean-American families.</h2>
        <h1 className="font-bold text-2xl mt-4">베추톡</h1>
        <h2 className="text-lg mt-2">한국계 미국인 가족을 위한 돌봄 대화 시작 카드.</h2>
        <button
          className="mt-4 w-1/2 mr-auto btn btn-primary"
          onClick={handleStartClick}
        >
          <label>Start</label>
          <label>시작</label>
        </button>
      </div>
      <Footer/>
    </div>
  );
}

export default LandingView;

