import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import Header from "../components/Header";
import Footer from "../components/Footer";

const OnboardView = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
const handleNextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      navigate("/cards");
    }
  };

  const steps = [
    {
      image: Logo,
      title: "Step 1: Introduction",
      paragraph: "Welcome to LettuceTalk! We’re excited to have you with us. Together, we’ll guide you through a series of question cards designed to help your family have meaningful conversations about caring for loved ones as they age.",
      titleTranslation: "1단계: 소개",
      paragraphTranslation: "LettuceTalk에 오신 것을 환영합니다! 함께해 주셔서 기쁩니다. 가족 구성원들이 나이가 들어감에 따라 돌봄에 대해 이야기할 수 있도록 돕는 질문 카드들을 통해 가족과 의미 있는 대화를 나눌 수 있도록 안내해드리겠습니다.",
    },
    {
      image: Logo,
      title: "Step 2: Setting the Table",
      paragraph: `Here are some guidelines to help set the table for a safe, compassionate discussion with your family on caregiving for your aging parents.

      1) Stay open and compassionate with yourself and one another as you walk through these cards.

      2) Take breaks whenever you need. Feel free to skip any questions that feel uncomfortable for you.

      3) It’s perfectly okay to feel strong emotions as you talk through these questions together. Take all the time you need.`,
      titleTranslation: "2단계: 상차리기",
      paragraphTranslation: `노부모님을 돌보는 문제에 대해 가족과 안전하고 따뜻한 대화를 나누기 위해 아래의 가이드를 참고해 주세요.

        1) 이 카드들을 함께 살펴보면서 서로에게, 그리고 자신에게 열린 마음과 따뜻한 마음을 유지하세요.

        2) 필요할 때는 언제든지 쉬어가세요. 불편한 질문은 건너뛰어도 괜찮습니다.

        3) 이 질문들을 함께 이야기하면서 강한 감정을 느끼는 것은 아주 자연스러운 일입니다. 충분히 시간을 가지세요.`,
    },
    {
      image: Logo,
      title: "Step 3: Ready to Go",
      paragraph: "You're all set! Click the button below to proceed.",
      titleTranslation: "3단계: 준비 완료",
      paragraphTranslation: "모두 준비되셨습니다! 아래 버튼을 눌러 진행해 주세요.",
    },
  ];

  return (
    <div className="w-full min-h-screen p-12 flex flex-col items-center bg-base-200">
      <Header/>
      {/* Stepper */}
      <ul className="steps w-lg mb-8">
        <li className={`step ${step >= 1 ? "step-primary" : ""}`}>Step 1</li>
        <li className={`step ${step >= 2 ? "step-primary" : ""}`}>Step 2</li>
        <li className={`step ${step >= 3 ? "step-primary" : ""}`}>Step 3</li>
      </ul>

      {/* Current Step Content */}
      <div className="shadow-xl w-96 flex flex-col bg-base-100 card p-12">
        <img src={steps[step - 1].image} className="w-36" alt={`Step ${step} Image`} />
        <h1 className="font-bold text-2xl mt-4">{steps[step - 1].title}</h1>
        <p className="text-lg mt-2 whitespace-pre-line">{steps[step - 1].paragraph}</p>
        <h1 className="font-bold text-2xl mt-4">{steps[step - 1].titleTranslation}</h1>
        <h2 className="text-lg mt-2 whitespace-pre-line">{steps[step - 1].paragraphTranslation}</h2>
        <button
          className="mt-4 w-1/2 mr-auto btn btn-primary flex flex-col"
          onClick={handleNextStep}
        >
          {step < 3 ? "Next 다음" : "Start 시작하기"}
        </button>
      </div>
      <Footer/>
    </div>
  );
};

export default OnboardView;

