import React from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your components
import LandingView from "./pages/LandingView";
import CardView from "./pages/CardView";
import NotFound from "./pages/NotFoundView";
import OnboardView from "./pages/OnboardView";
import StoryView from "./pages/StoryView";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingView />} />
        <Route path="/cards" element={<CardView />} />
        <Route path="/onboard" element={<OnboardView />} />
        <Route path="/story" element={<StoryView />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

