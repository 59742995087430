function Emoji ({ symbol, label, onClick, tabIndex = 0, className = '', style = {} }) {
  return (
    <span
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
      onClick={onClick}
      tabIndex={onClick ? tabIndex : -1}
      className={className}
      style={style}
      onKeyPress={(e) => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          onClick(e);
        }
      }}
    >
      {symbol}
    </span>
  )
}

export default Emoji;

