import { useState, useEffect } from "react";
import { KoreanQuestions } from "../data/KoreanQuestions";
import QuestionCard from "../components/QuestionCard";
import Logo from "../images/logo.png";
import Emoji from "../components/Emoji";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

function CardView() {
  const navigate = useNavigate();
  const questionsData = KoreanQuestions;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [order, setOrder] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  const allQuestions = questionsData.categories.flatMap(category => category.questions);
  const totalQuestions = allQuestions.length;

  useEffect(() => {
    // Generate a randomized order of indices when the component mounts
    const randomizedOrder = [...Array(totalQuestions).keys()].sort(() => Math.random() - 0.5);
    setOrder(randomizedOrder);
  }, [totalQuestions]);

  useEffect(() => {
    if (currentIndex >= totalQuestions) {
      setIsComplete(true);
    }
  }, [currentIndex, totalQuestions]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleNext = () => {
    if (currentIndex < totalQuestions - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setIsComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleRestart = () => {
    setCurrentIndex(0);
    setIsComplete(false);
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const progressPercentage = ((currentIndex + 1) / totalQuestions) * 100;

  return (
    <div className="bg-base-200 flex flex-col items-center min-h-screen p-12">
      <Header/>
      {isComplete ? (
        <div className="bg-base-100 card w-96 p-12">
          <img src={Logo} className="w-36" />
          <h1 className="text-2xl font-bold my-4">{`You're done!`}</h1>
          <div className="flex flex-col space-y-4">
            <p>From our family to yours, well done for your courage in holding this conversation.</p>
            <p>Remember these cards are just a starting resource. We encourage you to continue discussions at your own pace.</p>
            <p>If LettuceTalk helped you, please share us with other families and follow our team @impactmoonshots on <a className="link link-info" href="https://www.instagram.com/impactmoonshots/?img_index=1" target="_blank"> Instagram</a> and <a className="link link-info" href="https://www.linkedin.com/company/impact-moonshots/" target="_blank">LinkedIn</a>. See you at our official launch <Emoji symbol="🌕" label="full moon"/></p>
          </div>
          <h1 className="text-2xl font-bold my-4">{`모두 끝났습니다, 축하드려요!`}</h1>
          <div className="flex flex-col space-y-4">
            <p>우리 가족이 여러분의 가족에게 보내는 응원의 메시지입니다. 이 대화를 나누며 보여주신 용기에 박수를 보냅니다.</p>
            <p>이 카드는 시작에 불과합니다. 여러분의 속도에 맞춰 대화를 계속 이어가길 권장합니다.</p>
            <p>배추톡이 도움이 되었다면, 다른 가족들과 공유해 주시고 <a className="link link-info" href="https://www.instagram.com/impactmoonshots/?img_index=1" target="_blank">Instagram</a>과 <a className="link link-info" href="https://www.linkedin.com/company/impact-moonshots/" target="_blank">LinkedIn</a>에서 @impactmoonshots 팀을 팔로우해 주세요. 공식 출시에 함께해요 <Emoji symbol="🌕" label="full moon"/></p>
          </div>
          <div className="flex flex-row space-x-2">
            <button className="flex-1 btn mt-4 ml-auto" onClick={handleHomeClick}>Back to home</button>
            <button className="flex-1 btn btn-primary mt-4 ml-auto" onClick={handleRestart}>Restart</button>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full max-w-4xl mb-4">
            <div className="bg-base-300 rounded-full h-4 w-1/2 mx-auto">
              <div
                className="bg-primary h-4 rounded-full"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
            <p className="text-center mt-2">Card {`${currentIndex + 1} of ${totalQuestions}`}</p>
          </div>
          {order.length > 0 && (
            <QuestionCard
              title={allQuestions[order[currentIndex]].text}
              subtitle={`Question for ${capitalizeFirstLetter(allQuestions[order[currentIndex]].answerer)}`}
              translation={allQuestions[order[currentIndex]].translation}
              onPreviousClick={handlePrevious}
              onNextClick={handleNext}
              isPreviousDisabled={currentIndex === 0}
            />
          )}
        </>
      )}
      <Footer/>
    </div>
  );
}

export default CardView;

