import Emoji from "./Emoji";

function Footer() {
  return (
    <div className="mt-auto text-lg pt-4">
      Made with <Emoji symbol="💜" label="purple heart"/> by <a href="https://impactmoonshotfactory.com" target="_blank" className="link link-info">Impact Moonshots</a> // <a href="https://impactmoonshotfactory.com" target="_blank" className="link link-info">Impact Moonshots</a>가 💜으로 만들었습니다
    </div>
  );
}

export default Footer;

