import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";

function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleStoryClick = () => {
    navigate("/story");
  };

  return (
    <header className="w-full bg-transparent navbar rounded-xl mb-8">
      <div className="flex flex-row space-x-2 cursor-pointer" onClick={handleLogoClick}>
        <img src={Logo} alt="Logo" className="h-10" />
        <div>LettuceTalk</div>
        <div>배추톡</div>
      </div>
      <button className="ml-auto btn btn-outline" onClick={handleStoryClick}>
        Our Story / 우리 이야기
      </button>
    </header>
  );
}

export default Header;

