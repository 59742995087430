// src/data/KoreanQuestions.js
export const KoreanQuestions = {
  "categories": [
    {
      "category": "General Values and Preferences",
      "questions": [
        {
          "text": "How do you hope to be supported by family as you age?",
          "translation": "나이가 들면서 가족들에게 어떤 지원을 받고 싶으신가요?",
          "answerer": "parent"
        },
        {
          "text": "What legacy would you like to leave for your family and friends?",
          "translation": "가족과 친구들에게 어떤 유산을 남기고 싶으신가요?",
          "answerer": "parent"
        },
        {
          "text": "How do you envision your lifestyle in the coming years?",
          "translation": "앞으로 몇 년간 어떤 생활 방식을 원하시나요?",
          "answerer": "parent"
        },
        {
          "text": "What values do you hope our family continues to uphold?",
          "translation": "우리 가족이 계속해서 지키기를 바라는 가치는 무엇인가요?",
          "answerer": "parent"
        },
        {
          "text": "What do you hope to teach your children about our family’s cultural heritage?",
          "translation": "우리 가족의 문화적 유산에 대해 자녀들에게 어떤 것을 가르치고 싶으신가요?",
          "answerer": "parent"
        }
      ]
    },
    {
      "category": "Health Care and Medical Decisions",
      "questions": [
        {
          "text": "How do you feel about balancing medical treatments with your preferred lifestyle as you age?",
          "translation": "나이가 들면서 의료 치료와 원하는 생활 방식을 어떻게 균형 맞추고 싶으신가요?",
          "answerer": "parent"
        },
        {
          "text": "Is staying at home a priority for you as you think about your future living arrangements?",
          "translation": "미래의 거주지를 생각할 때 집에 머무는 것이 우선순위인가요?",
          "answerer": "parent"
        },
        {
          "text": "What are your preferences for managing discomfort or pain as you get older?",
          "translation": "나이가 들어가면서 불편함이나 통증 관리를 어떻게 하고 싶으신가요?",
          "answerer": "parent"
        },
        {
          "text": "Have you thought about creating an advance directive or living will?",
          "translation": "사전 의료 지시서나 생전 유언장을 작성할 생각을 해보셨나요?",
          "answerer": "parent"
        },
        {
          "text": "How would you like to handle medical decisions if you can no longer communicate your wishes?",
          "translation": "더 이상 의사를 전달할 수 없게 될 경우, 의료 결정을 어떻게 하고 싶으신가요?",
          "answerer": "parent"
        }
      ]
    },
    {
      "category": "Financial and Legal Matters",
      "questions": [
        {
          "text": "Would you like some help organizing your financial documents so they’re easier to access when our family needs them later on?",
          "translation": "나중에 우리 가족이 필요할 때 쉽게 찾을 수 있도록 재정 서류를 정리하는 데 도움이 필요하신가요?",
          "answerer": "parent"
        },
        {
          "text": "Are there any financial tasks you'd like assistance with now or in the future?",
          "translation": "현재 또는 미래에 도움이 필요할 것으로 예상되는 재정적인 업무가 있으신가요?",
          "answerer": "parent"
        },
        {
          "text": "Have you established a will or trust to manage your estate?",
          "translation": "재산을 관리하기 위해 유언장이나 신탁을 마련해 두셨나요?",
          "answerer": "parent"
        },
        {
          "text": "What are your preferences regarding the distribution of your assets?",
          "translation": "자산 배분에 대해 어떤 선호를 가지고 계신가요?",
          "answerer": "parent"
        },
        {
          "text": "Would you like to review your legal documents together to ensure everything is in place?",
          "translation": "모든 것이 잘 정리되어 있는지 법률 서류를 함께 검토해보기를 원하시나요?",
          "answerer": "parent"
        }
      ]
    },
    {
      "category": "Living Arrangements and Care",
      "questions": [
        {
          "text": "What kind of living environment would you prefer in the next few years?",
          "translation": "앞으로 몇 년 동안 어떤 생활 환경을 선호하시나요?",
          "answerer": "parent"
        },
        {
          "text": "How important is it to you to live close to family as you age?",
          "translation": "나이가 들어감에 따라 가족과 가까이 사는 것이 얼마나 중요하신가요?",
          "answerer": "parent"
        },
        {
          "text": "What would be your expectations if you need assistance with daily living in the future?",
          "translation": "미래에 일상 생활에서 도움이 필요하게 된다면 어떤 기대를 가지고 계신가요?",
          "answerer": "parent"
        },
        {
          "text": "Do you have a preference between receiving care from family members or professional caregivers?",
          "translation": "가족의 돌봄과 전문 간병인의 돌봄 중에서 선호하는 것이 있으신가요?",
          "answerer": "parent"
        },
        {
          "text": "What are your thoughts on exploring options like retirement communities or assisted living?",
          "translation": "은퇴 후 커뮤니티나 요양 시설 같은 옵션을 고려하는 것에 대해 어떻게 생각하시나요?",
          "answerer": "parent"
        }
      ]
    },
    {
      "category": "Emotional Support and Communication",
      "questions": [
        {
          "text": "What kind of support do you think would be most helpful as you plan for the future?",
          "translation": "미래를 계획하면서 어떤 지원이 가장 도움이 될 것이라고 생각하시나요?",
          "answerer": "parent"
        },
        {
          "text": "How would you like us to approach discussions about your health and future?",
          "translation": "당신의 건강과 미래에 대해 우리가 어떻게 논의하기를 원하시나요?",
          "answerer": "parent"
        },
        {
          "text": "How frequently would you feel comfortable having discussions about these plans?",
          "translation": "이러한 계획에 대해 얼마나 자주 논의하는 것이 편하시겠나요?",
          "answerer": "parent"
        },
        {
          "text": "What are your thoughts on how we communicate as a family?",
          "translation": "우리 가족의 의사소통 방식에 대해 어떻게 생각하시나요?",
          "answerer": "parent"
        },
        {
          "text": "Are there any particular topics you’d like to discuss or avoid as you plan for the future?",
          "translation": "미래를 계획하면서 논의하고 싶거나 피하고 싶은 주제가 있으신가요?",
          "answerer": "parent"
        }
      ]
    },
    {
      "category": "Parent to Child Questions",
      "questions": [
        {
          "text": "How do you feel about the responsibilities of caring for me as I get older?",
          "translation": "내가 나이가 들어가면서 나를 돌보는 책임에 대해 어떻게 생각하니?",
          "answerer": "child"
        },
        {
          "text": "What are your thoughts on balancing your own life and the caregiving duties?",
          "translation": "자신의 삶과 돌봄 의무를 어떻게 균형 맞출 생각이니?",
          "answerer": "child"
        },
        {
          "text": "Do you feel comfortable discussing these future plans together?",
          "translation": "이런 미래 계획에 대해 함께 이야기하는 것이 편하니?",
          "answerer": "child"
        },
        {
          "text": "How can I support you as we navigate these conversations?",
          "translation": "이 대화를 진행하는 데 있어 내가 너를 어떻게 도와줄 수 있을까?",
          "answerer": "child"
        },
        {
          "text": "Are there any concerns you have about my future care or health?",
          "translation": "내 미래 돌봄이나 건강에 대해 걱정되는 부분이 있니?",
          "answerer": "child"
        }
      ]
    },
    {
      "category": "Sibling Collaboration",
      "questions": [
        {
          "text": "How do you feel about sharing responsibilities as our parent begins to plan for the future?",
          "translation": "부모님이 미래를 준비하시기 시작할 때 우리가 책임을 나누는 것에 대해 어떻게 생각하시나요?",
          "answerer": "sibling"
        },
        {
          "text": "What are your thoughts on making decisions together regarding our parent's future care?",
          "translation": "부모님의 미래 돌봄에 대해 함께 결정하는 것에 대해 어떻게 생각하시나요?",
          "answerer": "sibling"
        },
        {
          "text": "How can we best work together to ensure we respect our parent's wishes?",
          "translation": "부모님의 바람을 존중하기 위해 우리가 어떻게 협력할 수 있을까요?",
          "answerer": "sibling"
        },
        {
          "text": "Do you have any concerns about our parent's health or future care needs?",
          "translation": "부모님의 건강이나 미래 돌봄 필요에 대해 걱정되는 부분이 있으신가요?",
          "answerer": "sibling"
        },
        {
          "text": "How can we support each other as we plan for our parent's future?",
          "translation": "부모님의 미래를 준비하면서 우리가 서로를 어떻게 도울 수 있을까요?",
          "answerer": "sibling"
        }
      ]
    }
  ]
}

